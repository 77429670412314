import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import BaseUrl from "../config/config.js";
import DeliveryNotesBillsList from "./DeliveryNotesBillsList.js";
import { Modal, ModalBody } from "reactstrap";
import SearchDeliveryNotesBills from "./SearchDeliveryNotesBills.js";
import ScrollToTop from "../services/scrolltop.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

var $ = require("jquery");
var moment = require("moment"); // require
var years = moment().diff("2017-01-01", "years") + 1;
class DeliveryNotesBills extends Component {
  constructor() {
    super();
    this.state = {
      openDeliveryNotes: [],
      bvAll: [],
      modalAsk: false,
      allYears: [],
      supplierAll: [],
      savedOpenDeliveryNotes: [],
      year: "Alle",
      supplier: "Alle",
      search: "",
      errorSearch: false,
    };
  }

  componentDidMount = () => {
    Promise.all([this.createYears(), this.readBvComplete()]).then((value) => {
      this.loadOpenDeliveryNotes();
    });

    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "supplier" || e.target.name === "year") {
      this.filter(e.target.name, e.target.value);
    }
  };
  handleSearch = (e) => {
    this.state.search = e.target.value;

    if (e.target.value === "") {
      const reset = this.state.openDeliveryNotes.map((index) => {
        return this.setState({
          openDeliveryNotes: [],
        });
      });

      Promise.all(reset).then((values) => {
        this.setState({
          openDeliveryNotes: this.state.savedOpenDeliveryNotes,
          errorSearch: false,
        });
      });
    }
  };
  createYears = () => {
    let help = [];
    [...Array(years)].map((i, idx) => {
      let calc = 2017 + idx;
      help = [...help, calc];
    });
    this.setState({
      allYears: help,
    });
  };
  toggle = () => {
    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        modalAsk: false,
      });
    } else {
      this.setState({
        modalAsk: !this.state.modalAsk,
      });
    }
  };

  loadOpenDeliveryNotes = () => {
    return fetch(BaseUrl + "api/deliveryNotesBills/readOpenDeliveryNotes.php")
      .then((response) => response.json())
      .then((responseJson) => {
        let help = [];
        responseJson.records[0].map((i) => {
          var indexItem = help.indexOf(i.supplierName);
          indexItem === -1 ? help.push(i.supplierName) : "";
        });
        help = help.sort((a, b) => a.localeCompare(b));
        return this.setState({
          supplierAll: help,
          openDeliveryNotes: responseJson.records[0],
          savedOpenDeliveryNotes: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  readBvComplete = () => {
    return fetch(BaseUrl + "api/all/readBvComplete.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          bvAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  removeBillFromList = (billsNr, notesNr) => {
    const openDeliveryNotes = this.state.openDeliveryNotes.filter(
      (elem) => !(elem.billsNr === billsNr && elem.notesNr === notesNr)
    );
    const reset = this.setState({
      openDeliveryNotes: [],
    });
    Promise.all([reset]).then((value) => {
      this.setState({
        openDeliveryNotes: openDeliveryNotes,
      });
    });
  };
  refreshOpenDeliveryNotes = () => {
    this.setState({
      modalAsk: !this.state.modalAsk,
      openDeliveryNotes: [],
      refresh: true,
    });
    return fetch(
      BaseUrl + "api/deliveryNotesBills/refreshOpenDeliveryNotes.php"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "true") {
          this.setState({
            refresh: false,
          });
          this.loadOpenDeliveryNotes();
        }
      })
      .catch((error) => {});
  };
  openAskModal = () => {
    this.toggle();
  };
  filter = (name, value) => {
    let help = this.state.savedOpenDeliveryNotes;
    let dateFrom = this.state.year + "-01-01";
    let dateTo = this.state.year + "12-31";

    if (name === "supplier" && value !== "Alle") {
      let checkDate =
        this.state.year === "Alle"
          ? help.filter((supplier) => supplier.supplierName === value)
          : help.filter(
              (supplier) =>
                supplier.supplierName === value &&
                supplier.date >= dateFrom &&
                supplier.date <= dateTo
            );
      help = checkDate;
    }
    if (name === "year" && value !== "Alle") {
      let checkSupplier =
        this.state.supplier === "Alle"
          ? help.filter(
              (supplier) =>
                supplier.date >= value + "-01-01" &&
                supplier.date <= value + "-12-31"
            )
          : help.filter(
              (supplier) =>
                supplier.supplierName === this.state.supplier &&
                supplier.date >= value + "-01-01" &&
                supplier.date <= value + "-12-31"
            );
      help = checkSupplier;
    }
    if (name === "year" && value === "Alle") {
      let checkDate = help.filter(
        (supplier) => supplier.supplierName === this.state.supplier
      );
      help = checkDate;
    }
    if (name === "supplier" && value === "Alle") {
      let checkSupplier = help.filter(
        (supplier) => supplier.date >= dateFrom && supplier.date <= dateTo
      );
      help = checkSupplier;
    }
    if (
      (value === "Alle" && name === "year" && this.state.supplier === "Alle") ||
      (value === "Alle" && name === "supplier" && this.state.year === "Alle")
    ) {
      const reset = this.setState({
        openDeliveryNotes: [],
      });
      Promise.all([reset]).then((value) => {
        this.setState({
          openDeliveryNotes: this.state.savedOpenDeliveryNotes,
        });
      });
    } else {
      const reset = this.setState({
        openDeliveryNotes: [],
      });
      Promise.all([reset]).then((value) => {
        this.setState({
          openDeliveryNotes: help,
          errorSearch: help.length >= 1 ? false : true,
        });
      });
    }
  };
  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
      this.setState({
        year: "Alle",
        supplier: "Alle",
      });
    }
  };

  search = (eingabe) => {
    var convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");
    if (moment(eingabe, "DD.MM.YYYY", true).isValid()) {
      convertEingabe = moment(eingabe, "DD-MM-YYYY").format("YYYY-MM-DD");
    }
    if (eingabe === "") {
      this.setState({
        openDeliveryNotes: this.state.savedOpenDeliveryNotes,
        errorSearch: false,
      });
    } else {
      this.setState({
        openDeliveryNotes: [],
      });

      return fetch(
        BaseUrl +
          "api/deliveryNotesBills/searchDeliveryNotesBills.php?eingabe=" +
          convertEingabe
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            this.setState({
              openDeliveryNotes: [],
              errorSearch: true,
            });
          } else {
            this.setState({
              openDeliveryNotes: responseJson.records[0],
            });
          }
        })
        .catch((error) => {});
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="deliveryNotesBills">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--yellow)" icon="truck-loading" /> offene
                Lieferscheine
              </h1>
            </div>
          </div>
        </section>
        <section className="show filter">
          <div className="row ">
            <div className="col-12 col-md-4 offset-md-4">
              <p>
                Sortieren nach <b>Jahr</b>
              </p>

              <select
                onChange={this.handleChange}
                name="year"
                value={this.state.year}
              >
                <option key="-1" value="Alle">
                  Alle
                </option>
                {this.state.allYears.map((year, i) => (
                  <option key={i} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-12 col-md-4 offset-md-4">
              <p>
                Sortieren nach <b>Lieferanten</b>
              </p>
              <select
                onChange={this.handleChange}
                name="supplier"
                value={this.state.supplier}
              >
                <option key="-1" value="Alle">
                  Alle
                </option>
                {this.state.supplierAll.map((sup, i) => (
                  <option key={i} value={sup}>
                    {sup}
                  </option>
                ))}
              </select>
            </div>
            <SearchDeliveryNotesBills
              search={this.state.search}
              onKeyPress={this.onKeyPress}
              handleSearch={this.handleSearch}
            />
          </div>
        </section>
        <section id="showOpenDeliveryNotes" className="display-Products">
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          <div className="container">
            <ul className="items-list">
              {this.state.openDeliveryNotes.map((i, idx) => {
                return (
                  <DeliveryNotesBillsList
                    key={idx}
                    billsNr={i.billsNr}
                    supplierId={i.supplierId}
                    supplier={i.supplierName}
                    notesDate={i.date === "" ? "2017-01-01" : i.date}
                    notesNr={i.notesNr}
                    bvId={i.bvId}
                    removeBillFromList={this.removeBillFromList}
                    bvAll={this.state.bvAll}
                    search={this.state.search}
                  />
                );
              })}
            </ul>
          </div>
        </section>
        <div className="text-center">
          <div className={this.state.refresh ? "lds-roller" : "hide"}>
            <div></div>
          </div>
        </div>

        <section>
          <button className="btn-refresh" onClick={this.openAskModal}>
            Aktualisieren <FontAwesomeIcon icon="sync-alt" />
          </button>
          <Modal isOpen={this.state.modalAsk} toggle={this.toggle}>
            <div className="modal-header">
              <div>
                <p>
                  Nach offenen Lieferscheinen suchen?
                  <br />
                </p>
              </div>

              <button className="close" onClick={this.toggle}>
                {" "}
                <FontAwesomeIcon size="2x" icon={["far", "times-circle"]} />
              </button>
            </div>
            <ModalBody>
              <h3 id="smaller-h3">
                Dieser Vorgang kann einige Minuten dauern!
              </h3>

              <div className=" btn-modal row">
                <div className="col">
                  <button onClick={this.toggle} className="btn-red">
                    Abbrechen <FontAwesomeIcon icon="trash" />
                  </button>
                </div>
                <div className="col">
                  <button
                    onClick={this.refreshOpenDeliveryNotes}
                    className="btn-green"
                  >
                    Suchen <FontAwesomeIcon icon={"sync-alt"} />
                  </button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        </section>
        <ScrollToTop />
      </React.Fragment>
    );
  }
}

export default DeliveryNotesBills;
