import React, { Component } from "react";
import ItemSearch from "./ItemSearch.js";
import ModalItem from "./ModalItem.js";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import { globalUnits } from "../global/units.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import BaseUrl from "../config/config.js";
var $ = require("jquery");

class Item extends Component {
  constructor() {
    super();
    this.state = {
      artNr: "",
      dsc: "",
      unit: "",
      artGroup: "",
      internArtNr: "",
      itemsAll: [],
      itemsAllCopy: [],
      errorSearch: false,
      search: "",
      useSearch: false,
      supplierAll: [],
      supplier: "",
      supplierId: "",
    };
  }

  componentDidMount = () => {
    Promise.all([this.readsuppliers()]).then(() => {
      this.loadItems();
    });

    if (sessionStorage.getItem("userData").indexOf("admin") === -1) {
      this.setState({
        disabledFieldset: true,
      });
    }
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "supplier") {
      this.readSupplierIdByName(e.target.value, true);
    }
    if (e.target.name === "supplier" && e.target.value === "-") {
      this.setState({
        supplierId: "",
      });
    }
  };
  handleChangeArtGroup = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });

    this.searchArtMax(e.target.value);
  };
  handleSubmit = (e) => {
    e.preventDefault();

    let item = {
      artNr: this.state.artNr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      supplierId: this.state.supplierId,
      internArtNr: this.state.internArtNr,
      artGroup: this.state.artGroup,
    };
    $.ajax({
      url: BaseUrl + "api/item/createItem.php",
      type: "POST",
      dataType: "text",
      data: JSON.stringify(item),
      success: function (response) {
        this.setState({
          artNr: "",
          dsc: "",
          unit: "",
          supplierId: "",
          supplier: "",
          internArtNr: "",
          artGroup: "",
        });
      }.bind(this),
      error: function (xhr, resp, text) {},
    });

    this.state.itemsAll.unshift({
      id: parseInt(this.state.itemsAll[0].id) + 1,
      artNr: this.state.artNr.trim(),
      dsc: this.state.dsc,
      unit: this.state.unit,
      supplierId: this.state.supplierId,
      internArtNr: this.state.internArtNr,
      artGroup: this.state.artGroup,
    });
    return this.setState({
      itemsAll: this.state.itemsAll,
    });
  };
  loadItems = () => {
    return fetch(BaseUrl + "api/item/readItems.php")
      .then((response) => response.json())
      .then((responseJson) => {
        return this.setState({
          itemsAll: responseJson.records[0],
          itemsAllCopy: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  onKeyPress = (e) => {
    if (e.key === "Enter") {
      this.search(e.target.value);
    }
  };
  handleSearch = (e) => {
    this.state.search = e.target.value;

    if (e.target.value === "") {
      this.setState({
        itemsAll: this.state.itemsAllCopy,
        errorSearch: false,
      });
    }
  };

  search = (eingabe) => {
    let convertEingabe = eingabe.replace(/ /g, "%").replace("+", "%2B");

    if (eingabe === "") {
      this.setState({
        itemsAll: this.state.itemsAllCopy,
        errorSearch: false,
      });
    } else {
      this.setState({
        itemsAll: [],
      });

      return fetch(
        BaseUrl + "api/item/searchItem.php?eingabe=" + convertEingabe
      )
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            console.log(responseJson.message);
            this.setState({
              itemsAll: [],
              errorSearch: true,
            });
          } else {
            this.setState({
              errorSearch: false,
              itemsAll: responseJson.records[0],
            });
          }
        })
        .catch((error) => {});
    }
  };
  searchArtMax = (eingabe) => {
    if (eingabe === "") {
      this.setState({
        errorSearch: false,
      });
    } else {
      return fetch(BaseUrl + "api/item/searchMaxArtNr.php?eingabe=" + eingabe)
        .then((response) => response.json())
        .then((responseJson) => {
          if (responseJson.message === "No items found.") {
            this.setState({
              internArtNr: "-",
            });
          } else {
            if (responseJson.records[0].max === null) {
              let countUp = parseInt(0) + parseInt(1);
              this.setState({
                internArtNr: String(countUp).padStart(7, "0"),
              });
            } else {
              let countUp = parseInt(responseJson.records[0].max) + parseInt(1);
              this.setState({
                internArtNr: String(countUp).padStart(7, "0"),
              });
            }
          }
        })
        .catch((error) => {});
    }
  };

  readsuppliers = () => {
    return fetch(BaseUrl + "api/all/readSupplierNames.php")
      .then((response) => response.json())
      .then((responseJson) => {
        this.setState({
          supplierAll: responseJson.records[0],
        });
      })
      .catch((error) => {});
  };

  readSupplierNameById = (id, bool) => {
    let found = this.state.supplierAll.find((name) => name.supplierId === id);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplier: found.supplierName,
      });
    } else {
      return found.supplierName;
    }
  };
  readSupplierIdByName = (name, bool) => {
    let found = this.state.supplierAll.find((id) => id.supplierName === name);
    if (found === undefined) {
      return "";
    }
    if (bool === true) {
      return this.setState({
        supplierId: found.supplierId,
      });
    } else {
      return found.supplierId;
    }
  };

  autocompleteBvSupplier = (e) => {
    if (e.keyCode === 9 && e.target.name === "supplierId") {
      this.readSupplierNameById(e.target.value, true);
    }
  };
  render() {
    return (
      <React.Fragment>
        <section id="insertItem">
          <div className="wrapper-btn-text row">
            <div className="col-md-2">
              <Link to="/home">
                <FontAwesomeIcon icon="arrow-left" /> Home
              </Link>
            </div>
            <div className="col-md-8">
              <h1>
                <FontAwesomeIcon color="var(--greenSeyband)" icon="list-ol" />{" "}
                Artikelstamm
              </h1>
            </div>
          </div>
          <h4>Artikel Hinzufügen</h4>
          <form className="items" onSubmit={this.handleSubmit}>
            <fieldset disabled={this.state.disabledFieldset}>
              <div className="row">
                <div className="col-md-2">
                  <p>Artikel-Nr</p>
                  <input
                    required
                    type="text"
                    name="artNr"
                    onChange={this.handleChange}
                    value={this.state.artNr}
                  />
                </div>
                <div className="col-md-8">
                  <p>Artikelbezeichnung</p>
                  <input
                    required
                    type="text"
                    name="dsc"
                    onChange={this.handleChange}
                    value={this.state.dsc}
                  />
                </div>
                <div className="col-md-2">
                  <p>Einheit</p>
                  <select
                    name="unit"
                    onChange={this.handleChange}
                    value={this.state.unit}
                  >
                    {globalUnits.map((unit, idx) => {
                      return (
                        <option key={idx} value={unit}>
                          {unit}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-2 tab">
                  <p>Lieferanten-Nr</p>
                  <input
                    required
                    type="text"
                    name="supplierId"
                    placeholder="-"
                    onChange={this.handleChange}
                    value={this.state.supplierId}
                    onKeyDown={this.autocompleteBvSupplier}
                  />
                  <span>↹</span>
                </div>
                <div className="col-3">
                  <p>Lieferant</p>
                  <select
                    name="supplier"
                    value={this.state.supplier}
                    onChange={this.handleChange}
                  >
                    <option value="-">-</option>
                    {this.state.supplierAll.map((supplier, i) => (
                      <option key={i} value={supplier.supplierName}>
                        {supplier.supplierName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <p>Warengruppe</p>
                  <select
                    name="artGroup"
                    onChange={this.handleChangeArtGroup}
                    value={this.state.artGroup}
                  >
                    <option key="-1" value="-">
                      -
                    </option>
                    <option key={"w1"} value="01">
                      01 - Profile
                    </option>
                    <option key={"w2"} value="02">
                      02 - Gipsplatten
                    </option>
                    <option key={"w3"} value="03">
                      03 - Spachtelmasse
                    </option>
                    <option key={"w4"} value="04">
                      04 - Montagezubehör
                    </option>
                    <option key={"w5"} value="05">
                      05 - Werkzeuge
                    </option>
                    <option key={"w6"} value="06">
                      06 - Büromaterial
                    </option>
                    <option key={"w7"} value="07">
                      07 - Logistikkosten
                    </option>
                    <option key={"w8"} value="08">
                      08 - Entsorgung
                    </option>
                    <option key={"w9"} value="09">
                      09 - Arbeitskleidung
                    </option>
                    <option key={"w10"} value="10">
                      10 - KFZ-Zubehör
                    </option>
                    <option key={"w11"} value="11">
                      11 - Dämmung
                    </option>
                    <option key={"w12"} value="12">
                      12 - Türen
                    </option>
                    <option key={"w13"} value="13">
                      13 - Zargen
                    </option>
                    <option key={"w14"} value="14">
                      14 - Drückergarnituren
                    </option>
                    <option key={"w15"} value="15">
                      15 - Bodenbeläge
                    </option>
                  </select>
                </div>
                <div className="col-2">
                  <p>Artikel-Nr Intern</p>
                  <input
                    type="text"
                    name="internArtNr"
                    onChange={this.handleChange}
                    value={this.state.internArtNr}
                  />
                </div>

                <div className="offset-1 col-md-2">
                  <button className="btn-create-item">
                    Artikel speichern <FontAwesomeIcon icon="save" />
                  </button>
                </div>
              </div>
            </fieldset>
          </form>
        </section>
        <section id="showItems" className="display-item">
          <h4>Übersicht Artikel</h4>
          <ItemSearch
            search={this.state.search}
            onKeyPress={this.onKeyPress}
            handleSearch={this.handleSearch}
          />
          <p className={this.state.errorSearch ? "show errorSearch" : "hide"}>
            Kein Suchergebnis
          </p>
          {this.state.supplierAll !== undefined ? (
            <ul className="items-list">
              {this.state.itemsAll.map((item) => {
                return (
                  <ModalItem
                    key={item.id}
                    id={item.id}
                    artNr={item.artNr}
                    dsc={item.dsc}
                    unit={item.unit}
                    supplierId={item.supplierId}
                    supplier={this.readSupplierNameById(item.supplierId, false)}
                    internArtNr={item.internArtNr}
                    supplierAll={this.state.supplierAll}
                    artGroup={item.artGroup}
                    search={this.state.search}
                  />
                );
              })}
            </ul>
          ) : (
            ""
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default Item;
