import React, { Component } from "react";
 import "@fortawesome/fontawesome-free-solid";
import { Link } from "react-router-dom";
import Textarea from "react-textarea-autosize";
import BaseUrl from "../config/config.js";
import ModalOpenDeliveryNote from "./ModalOpenDeliveryNote.js";
var Highlight = require('react-highlighter');

var $ = require("jquery");
var moment = require('moment'); // require
 class DeliveryNotesBillsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplierId: this.props.supplierId,
      supplier: this.props.supplier,
      billsNr: this.props.billsNr,
      key: this.props.id,
      notesDate: this.props.notesDate,
      notesNr: this.props.notesNr,
      bvId: this.props.bvId,
      showItem: [],
      showModal: false,
      bvAll: this.props.bvAll
    };
  }
 
  generateModal = () => {
    let searchHelpFunc = [];
    this.setState({
      showItem: [],
    });
    fetch(
      BaseUrl +
        "api/deliveryNotesBills/readBill.php?billNr=" +
        this.state.billsNr + "&notesNr=" + this.state.notesNr
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.message === "No items found.") {
          this.setState({
            showItem: [],
          });
        } else {
          responseJson.records.map((item, sidx) => {
            Object.keys(item).map((billsNr, idx) => {
              const searchArticle = item[billsNr].map((item, ix) => {
                return {
                  key: sidx,
                  notesDate: item.notesDate,
                  id: item.id,
                  itemId: item.itemId,
                  itemDsc: item.itemDsc,
                  itemAmnt: item.itemAmnt,
                  itemUnit: item.itemUnit,
                  pricePiece: item.pricePiece,
                  discount: item.discount,
                  priceComplete: item.priceComplete,
                };
              });
              const searchNotes = item[billsNr].slice(0, 1).map((item, ix) => {
                return {
                  key: sidx,
                  notesDate: item.notesDate,
                  id: item.id,
                  billsNr: item.billsNr,
                  notesNr: item.notesNr,
                  supplier: this.state.supplier,
                  supplierId: item.supplierId,
                  itemBv: item.bvId,
                  itemBvId: item.bvId,
                  articles: searchArticle,
                };
              });
              return (searchHelpFunc = [...searchHelpFunc, ...searchNotes]);
            });
            return this;
          });
           this.setState({
            showItem: searchHelpFunc,
            showModal: true,
          });
        }
      });
  };
 
  render() {
    return (
      <React.Fragment>
        <li key={this.state.key}   onClick={this.generateModal}>
            <div className="row fullwidth showSpan">
            <p className="col-3"><span>RechnungsNr</span><br/> <Highlight id="font-normal"  search={this.props.search}> {this.state.billsNr}</Highlight> </p>
            <p className="col"><span>Lieferant ID</span><br/> <Highlight id="font-normal"  search={this.props.search}> {this.state.supplierId}</Highlight> </p>
            <p className="col"><span>Lieferant</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.supplier}</Highlight></p>
            <p className="col"><span>Datum</span><br/><Highlight id="font-normal"  search={this.props.search}>{moment(this.state.notesDate).format('DD.MM.YYYY')}</Highlight></p>
            <p className="col-3"><span>LieferscheinNr</span><br/><Highlight id="font-normal"  search={this.props.search}>{this.state.notesNr}</Highlight></p>

           </div>
          {this.state.showItem.map((item, idx) => {
            return (
              <ModalOpenDeliveryNote
                key={item.notesNr}
                modal={true}
                notesDate={item.notesDate}
                notesNr={item.notesNr}
                supplier={item.supplier}
                supplierId={item.supplierId}
                 bvId={this.state.bvId}
                articles={item.articles}
                bvAll={this.state.bvAll}
                billsNr={this.state.billsNr}
                removeBillFromList={this.props.removeBillFromList}
              />
            );
          })}
        </li>
      </React.Fragment>
    );
  }
}

export default DeliveryNotesBillsList;
